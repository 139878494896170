<template>
  <div>
    <b-modal id="modal-data-edit" ref="modal" title="Data" @show="resetModal" @hidden="resetModal" @ok="handleOk">
      <b-overlay :show="isSaving" no-wrap></b-overlay>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-row class="my-1">
          <b-col sm="4">
            <label> Station </label>
          </b-col>
          <b-col sm="8">
            <b-form-group>
              <b-form-select
                size="sm"
                v-model="data.bst_code"
                :options="stationList"
                value-field="code"
                text-field="name"
                placeholder="Station"
                :state="!(data.bst_code == null)"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col sm="4">
            <label> Date </label>
          </b-col>
          <b-col sm="8">
            <b-input-group size="sm">
              <b-form-input
                id="example-input"
                v-model="data.date"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                disabled
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  v-model="data.date"
                  button-only
                  right
                  locale="en-US"
                  aria-controls="example-input"
                ></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col sm="4">
            <label> Genus </label>
          </b-col>
          <b-col sm="8">
            <b-input-group size="sm" type="text">
              <b-form-input v-model="data.genus"></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col sm="4">
            <label> Density (cell/liter) </label>
          </b-col>
          <b-col sm="8">
            <b-input-group size="sm" type="text">
              <b-form-input v-model="data.density"></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col sm="4">
            <label> Comment </label>
          </b-col>
          <b-col sm="8">
            <b-input-group size="sm" type="text">
              <b-form-textarea id="textarea" v-model="data.comment" rows="4" max-rows="6"></b-form-textarea>
            </b-input-group>
          </b-col>
        </b-row>
      </form>
    </b-modal>
    <!--end::Body-->
  </div>
</template>

<script>
import * as Services from '../services.js';

export default {
  name: 'data-edit-comp',
  data() {
    return {
      mode: null,
      data: {
        id: 0,
        bat_id: '',
        bst_code: '',
        province: '',
        date: '',
        genus: null,
        density: null,
        comment: null,
      },
      stationList: null,
      stationCodeState: null,
      dateState: null,
      timeState: null,
      depthState: null,
      isShow: false,
      isSaving: false,
    };
  },
  mounted() {
    //load station
    this.$store
      .dispatch(Services.GET_STATION_LIST)
      .then((res) => {
        this.isReady = true;
        this.stationList = res.data;
      })
      .catch((error) => {
        // console.log(error);
      });
  },
  methods: {
    showModal(options) {
      this.mode = options.mode;
      if (options.mode == 'edit') {
        // this.data = options.data;
        this.data.id = options.data.id;
        this.data.bat_id = options.data.bat_id;
        this.data.bst_code = options.data.bst_code;
        this.data.province = options.data.province;
        this.data.date = options.data.date;
        this.data.genus = options.data.genus;
        this.data.density = options.data.density;
        this.data.comment = options.data.comment;
      }
      this.$bvModal.show('modal-data-edit');
    },
    getData(id) {
      if (this.mode == 'edit') {
        this.$store
          .dispatch(Services.GET_BIODIVERSITY_DATA, {id: id})
          // go to which page after successfully login
          .then((res) => {
            this.data = res.data[0];
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    save() {
      this.isSaving = true;
      this.$store
        .dispatch(Services.SAVE_BIODIVERSITY_DATA, this.data)
        // go to which page after successfully login
        .then((res) => {
          if (res.status) {
            // this.data = res.data[0];
            this.isSaving = false;
            this.$bvToast.toast('Data saved.', {
              title: 'Successfully',
              variant: 'success',
              solid: true,
            });
            this.$parent.getDataList();
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('modal-data-edit');
            });
          } else {
            // this.data = res.data[0];
            this.isSaving = false;
            this.$bvToast.toast(res.message, {
              title: 'Error!',
              variant: 'danger',
              solid: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.isSaving = false;
        });
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.stationCodeState = valid;
      return valid;
    },
    resetModal() {},
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      //Save Data
      this.save();
    },
  },
  watch: {
    'data.bst_code': function (oldValue, newValue) {
      for (const key in this.stationList) {
        if (Object.hasOwnProperty.call(this.stationList, key)) {
          const element = this.stationList[key];
          if (element.code == this.data.bst_code) {
            this.data.province = element.province;
          }
        }
      }
    },
  },
};
</script>

<style></style>
